import React from 'react';
import PropTypes from 'prop-types';
import OrganizationIcon from '../components/GroupManagement/Organizations/OrganizationIcon';
import GroupIcon from '../components/GroupManagement/Group/GroupIcon';
import CommentNavMenu from '../containers/comments/CommentNavMenu';
import Icon, {
  AudioOutlined,
  BankOutlined,
  BarcodeOutlined,
  BarsOutlined,
  ContactsOutlined,
  FolderOutlined,
  GoldOutlined,
  IdcardOutlined,
  PaperClipOutlined,
  PlaySquareOutlined,
  PoweroffOutlined,
  ProfileOutlined,
  QuestionOutlined,
  ReadOutlined,
  ShoppingCartOutlined,
  TagOutlined,
  TeamOutlined,
  UserOutlined,
  LockOutlined,
  DropboxOutlined,
  DollarOutlined
} from '@ant-design/icons';

import { Menu, Layout } from 'antd';
import { Link } from 'react-router-dom';
const { SubMenu } = Menu;
const { Sider } = Layout;

const menuItemStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
};

const Sidebar = props => {
  const { defaultOpenKeys, onLogout, ...other } = props;
  return (
    <Sider
      width={256}
      style={{ backgroundColor: '#fff', borderRight: '1px solid #e8e8e8' }}
    >
      <Menu mode="inline" defaultOpenKeys={defaultOpenKeys}>
        <SubMenu
          key="accounts"
          title={
            <span style={menuItemStyles}>
              <TeamOutlined />
              Accounts
            </span>
          }
        >
          <Menu.Item style={menuItemStyles}>
            <Link to="/account/search">
              <UserOutlined />
              <span>User</span>
            </Link>
          </Menu.Item>
          <Menu.Item style={menuItemStyles}>
            <Link to="/accounts/faculty">
              <BankOutlined />
              <span>Faculty</span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="group-management"
          title={
            <span style={menuItemStyles}>
              <ContactsOutlined />
              Group Management
            </span>
          }
        >
          <Menu.Item>
            <Icon component={OrganizationIcon} />
            <Link to="/group-management/organizations">Organizations</Link>
          </Menu.Item>
          <Menu.Item>
            <Icon component={GroupIcon} />
            <Link to="/group-management/groups">Groups</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="content"
          title={
            <span style={menuItemStyles}>
              <GoldOutlined />
              Content
            </span>
          }
        >
          <Menu.Item style={{ paddingLeft: 48 }}>
            <Link to="/content/tags" style={menuItemStyles}>
              <TagOutlined />
              <span>Tags</span>
            </Link>
          </Menu.Item>
          <SubMenu
            key="audio"
            title={
              <span style={menuItemStyles}>
                <AudioOutlined />
                Audio Content
              </span>
            }
          >
            <Menu.Item style={{ paddingLeft: 48 }}>
              <Link to="/content/chapters" style={menuItemStyles}>
                <AudioOutlined />
                <span>Chapters</span>
              </Link>
            </Menu.Item>
            <Menu.Item style={{ paddingLeft: 48 }}>
              <Link to="/content/episodes" style={menuItemStyles}>
                <AudioOutlined />
                <span>Episodes</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item style={{ paddingLeft: 48 }}>
            <Link to="/content/questions" style={menuItemStyles}>
              <QuestionOutlined />
              <span>Questions</span>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/content/assessments" style={menuItemStyles}>
              <BarsOutlined />
              <span>Assessments</span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="courses"
          title={
            <span style={menuItemStyles}>
              <ReadOutlined />
              Courses
            </span>
          }
        >
          <Menu.Item>
            <Link to="/courses" style={menuItemStyles}>
              <FolderOutlined />
              <span>All</span>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/courses/podcast" style={menuItemStyles}>
              <AudioOutlined />
              <span>Audio</span>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/courses/video" style={menuItemStyles}>
              <PlaySquareOutlined />
              <span>Video</span>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/courses/qbank" style={menuItemStyles}>
              <QuestionOutlined />
              <span>QBank</span>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/courses/practice-exam" style={menuItemStyles}>
              <ProfileOutlined />
              <span>Practice Exams</span>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/courses/reference-guides" style={menuItemStyles}>
              <ProfileOutlined />
              <span>Reference Guides</span>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/courses/bootcamp-assessment" style={menuItemStyles}>
              <BarsOutlined />
              <span>Bootcamp Assessments</span>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/courses/assessment" style={menuItemStyles}>
              <BarsOutlined />
              <span>Assessments</span>
            </Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key="discounts"
          title={
            <span style={menuItemStyles}>
              <DollarOutlined />
              Manage Discounts
            </span>
          }
        >
          <Menu.Item style={menuItemStyles}>
            <Link to="/promotions">
              <BarcodeOutlined />
              <span>Manage promos</span>
            </Link>
          </Menu.Item>

          <Menu.Item style={menuItemStyles}>
            <Link to="/bundles">
              <DropboxOutlined />
              <span>Manage Bundles</span>
            </Link>
          </Menu.Item>

          <Menu.Item style={menuItemStyles}>
            <Link to="/associations">
              <IdcardOutlined />
              <span>Manage Associations</span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="orders"
          title={
            <span style={menuItemStyles}>
              <ShoppingCartOutlined />
              Orders
            </span>
          }
        >
          <Menu.Item>
            <Link to="/order/search">Search</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/order/create">Create</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="reports"
          title={
            <span style={menuItemStyles}>
              <PaperClipOutlined />
              Reports
            </span>
          }
        >
          <Menu.Item>
            <Link to="/report/evaluation">Evaluations</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/report/acep">ACEP Reports</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/report/referencePages/cmeAccreditation">
              Cme Accredidation Info
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item style={menuItemStyles}>
          <Link to="/products">
            <LockOutlined />
            <span>Manage Products</span>
          </Link>
        </Menu.Item>
        <CommentNavMenu {...other} />
        <Menu.Item onClick={onLogout}>
          <PoweroffOutlined />
          <span>Log out</span>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

Sidebar.propTypes = {
  defaultOpenKeys: PropTypes.arrayOf(PropTypes.string),
  onLogout: PropTypes.func.isRequired
};

Sidebar.defaultProps = {
  defaultOpenKeys: []
};

export default Sidebar;

import React from 'react';
import { Route } from 'react-router-dom';

import EvaluationReport from '../containers/report/EvaluationReport';
import AcepReport from '../containers/report/AcepReport';
import CmeAccreditationInfo from '../containers/referencePages/cmeAccreditation';

const ReportRoutes = () => {
  return (
    <>
      <Route path="/report/evaluation" component={EvaluationReport} />
      <Route path="/report/acep" component={AcepReport} />
      <Route
        path="/report/referencePages/cmeAccreditation"
        component={CmeAccreditationInfo}
      />
    </>
  );
};

export default ReportRoutes;

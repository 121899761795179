import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useQuery, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Table, Space, Tag, Modal, Button } from 'antd';
import {
  FilterOutlined,
  CaretUpOutlined,
  CaretDownOutlined
} from '@ant-design/icons';
import { format } from 'date-fns';

import './cmeAccreditation.css';

const GET_COURSE_IDS_WHERE_WEB_INTERNAL_TRUE = gql`
  query GetCmeAccredidationInfo {
    coursesList(condition: { showOnWebInternal: true }, orderBy: TITLE_ASC) {
      id
      title
    }
  }
`;

const GET_CME_ACCREDIDATION_INFO = gql`
  query GetCmeAccredidationInfo($coursesIdFilter: [UUID!]) {
    coursesList(filter: { id: { in: $coursesIdFilter } }, orderBy: TITLE_ASC) {
      id
      title
      courseType {
        shortname
      }
      cmeBucketsList(orderBy: ACTIVATED_AT_DESC) {
        activatedAt
        expiredAt
        isActive
        isDefaultCmeBucket
        maxCredits
        title
        cmeAccreditor {
          creditType
          name
        }
      }
    }
  }
`;

const useCourseData = () => {
  const { data: coursesList, loading: loadingCoursesList } = useQuery(
    GET_COURSE_IDS_WHERE_WEB_INTERNAL_TRUE
  );

  const { data, loading, error } = useQuery(GET_CME_ACCREDIDATION_INFO, {
    variables: {
      coursesIdFilter: coursesList
        ? coursesList.coursesList.map(course => course.id)
        : []
    },
    skip: !coursesList || coursesList.coursesList.length === 0
  });

  return { data, loading, error, loadingCoursesList };
};

const formatData = data => {
  const { coursesList } = data;

  const groupedByCourseType = _.groupBy(coursesList, 'courseType.shortname');
  const groupedData = _.map(groupedByCourseType, (courses, courseType) => {
    return {
      courseType,
      courses: _.flatMap(courses, course => {
        return course.cmeBucketsList.map((bucket, index) => ({
          key: `${course.id}-${bucket.title}-${index}`,
          courseId: course.id,
          courseType,
          courseTitle: course.title,
          bucketTitle: bucket.title,
          activeDates: `${format(
            new Date(bucket.activatedAt),
            'MMMM dd, yyyy'
          )} - ${format(new Date(bucket.expiredAt), 'MMMM dd, yyyy')}`,
          maxCredits: bucket.maxCredits,
          accreditorName: bucket.cmeAccreditor.name
        }));
      })
    };
  });

  return _.flatMap(groupedData, 'courses');
};

const CmeAccreditationInfo = () => {
  const history = useHistory();
  const [courseData, setCourseData] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const { data, loading, error, loadingCoursesList } = useCourseData();

  useEffect(() => {
    if (data) {
      setCourseData(formatData(data));
    }
  }, [data]);

  if (loadingCoursesList || loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const navigateToCourseDetails = (courseId, courseTitle) => {
    Modal.confirm({
      title: 'Navigate to Course Details Page',
      content: (
        <div>
          Are you sure you want to navigate to <strong>{courseTitle}'s</strong>{' '}
          course page?
        </div>
      ),
      onOk: () => {
        history.push(`/course/${courseId}`);
      }
    });
  };

  const clearFilters = () => {
    setFilteredInfo({});
  };

  const clearSorting = () => {
    setSortedInfo({});
  };

  const clearAll = () => {
    clearFilters();
    clearSorting();
  };

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const courseTypesFilter = _.uniq(
    courseData?.map(item => item.courseType)
  ).map(type => ({
    text: type,
    value: type
  }));

  const courseTitlesFilter = _.sortBy(
    _.uniqBy(
      courseData.map(course => ({
        text: course.courseTitle,
        value: course.courseId
      })),
      'value'
    ),
    'text'
  );

  const accreditorNamesFilter = _.uniq(
    courseData.map(item => item.accreditorName)
  ).map(name => ({
    text: name,
    value: name
  }));

  const columns = [
    {
      title: 'Course ID',
      dataIndex: 'courseId',
      key: 'courseId',
      hidden: true
    },
    {
      title: 'Course Type',
      dataIndex: 'courseType',
      key: 'courseType',
      sorter: (a, b) => a.courseType.localeCompare(b.courseType),
      sortOrder:
        sortedInfo.columnKey === 'courseType' ? sortedInfo.order : null,
      filters: courseTypesFilter,
      filteredValue: filteredInfo.courseType || null,
      onFilter: (value, record) => {
        return record.courseType === value;
      },
      filterMultiple: true,
      filterSearch: true
    },
    {
      title: 'Course Title',
      dataIndex: 'courseTitle',
      key: 'courseTitle',
      sorter: (a, b) => a.courseTitle.localeCompare(b.courseTitle),
      sortOrder:
        sortedInfo.columnKey === 'courseTitle' ? sortedInfo.order : null,
      filters: courseTitlesFilter,
      filteredValue: filteredInfo.courseTitle || null,
      onFilter: (value, record) => {
        return record.courseId.includes(value);
      },
      filterMultiple: true,
      filterSearch: true,
      render: courseTitle => <strong>{courseTitle}</strong>
    },
    {
      title: 'Accreditation Period Title',
      dataIndex: 'bucketTitle',
      key: 'bucketTitle'
    },
    {
      title: 'Active Dates',
      dataIndex: 'activeDates',
      key: 'activeDates',
      sorter: (a, b) =>
        new Date(a.activeDates.split(' - ')[0]) -
        new Date(b.activeDates.split(' - ')[0]),
      sortOrder:
        sortedInfo.columnKey === 'activeDates' ? sortedInfo.order : null,
      render: activeDates => {
        const [startDate, endDate] = activeDates
          .split(' - ')
          .map(date => new Date(date));
        const today = new Date();
        const isActive = today >= startDate && today <= endDate;
        const className = isActive ? 'active' : 'inherit';
        return (
          <span className={className}>
            {activeDates}
            {isActive ? (
              <span role="img" aria-label="sparkles">
                {' '}
                ✨
              </span>
            ) : (
              ''
            )}
          </span>
        );
      }
    },
    {
      title: 'Max Credits',
      dataIndex: 'maxCredits',
      key: 'maxCredits',
      sorter: (a, b) => a.maxCredits - b.maxCredits,
      sortOrder: sortedInfo.columnKey === 'maxCredits' ? sortedInfo.order : null
    },
    {
      title: 'Accreditor',
      dataIndex: 'accreditorName',
      key: 'accreditorName',
      render: accreditorName => {
        let color;
        switch (accreditorName) {
          case 'AAPA':
            color = 'cyan';
            break;
          case 'Hippo Education':
            color = 'blue';
            break;
          case 'AAP':
            color = 'green';
            break;
          case 'UCI':
            color = 'lime';
            break;
          case 'AAFP':
            color = 'gold';
            break;
          default:
            color = 'red';
        }
        return (
          <Tag color={color} key={accreditorName}>
            {accreditorName}
          </Tag>
        );
      },
      sorter: (a, b) => a.accreditorName.localeCompare(b.accreditorName),
      sortOrder:
        sortedInfo.columnKey === 'accreditorName' ? sortedInfo.order : null,
      filters: accreditorNamesFilter,
      filteredValue: filteredInfo.accreditorName || null,
      onFilter: (value, record) => {
        return record.accreditorName === value;
      },
      filterMultiple: true
    }
  ].filter(column => !column.hidden);

  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }}>
        <h1>CME Accreditation Info</h1>
        <div className="button-container">
          <Button
            className="button-container--button"
            icon={<FilterOutlined />}
            type="default"
            onClick={clearFilters}
          >
            Clear all filters
          </Button>
          <Button
            className="button-container--button"
            icon={
              <span className="button-container--button--sortIcons">
                <CaretUpOutlined />
                <CaretDownOutlined />
              </span>
            }
            type="default"
            onClick={clearSorting}
          >
            Clear all sorting
          </Button>
          <Button
            className="button-container--button"
            type="primary"
            onClick={clearAll}
          >
            Clear all filters and sorting
          </Button>
        </div>
        <Table
          dataSource={courseData}
          columns={columns}
          pagination={false}
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'evenRow' : 'oddRow'
          }
          onChange={handleChange}
          onRow={record => ({
            onClick: () =>
              navigateToCourseDetails(record.courseId, record.courseTitle)
          })}
        />
      </Space>
    </div>
  );
};

export default CmeAccreditationInfo;
